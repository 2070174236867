<template>
  <b-modal hide-header hide-footer v-model="isShow" centered>
    <div class="d-block">
      <div class="d-flex align-items-center">
        <div style="flex-basis: 40%">
          <img :src="detailInfo.product.pic_url" width="100%" height="100%" />
        </div>
        <div class="ml-4">
          <h4>{{ detailInfo.title }}</h4>
          <h5>商品頁序號：{{ this.detailInfo.product.sale_page_code }}</h5>
          <div>單價：{{ this.detailInfo.product.price }}</div>
          <div>款式：{{ this.detailInfo.product.sku }}</div>
          <b-form inline class="d-flex">
            <label class="mr-sm-2">購買數量：</label>
            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              style="width: 30%"
              type="number"
              v-model.number="quantity"
              :formatter="formatter"
            ></b-form-input>
            <b-btn variant="primary"
              ><i class="fa fa-refresh" aria-hidden="true"></i>更新網址</b-btn
            >
          </b-form>
        </div>
      </div>

      <div class="border border-secondary mt-4 p-4">
        <a :href="payLink">{{ payLink }}</a>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "GroupbuyingsLinkModal",
  props: ["show", "detailInfo"],
  data() {
    return {
      quantity: 0,
    };
  },
  computed: {
    payLink() {
      return "http://";
    },
    isShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },

  methods: {
    formatter(value) {
      if (value < 0) {
        return 0;
      }
      return Math.round(value);
    },
  },
};
</script>

<style></style>
