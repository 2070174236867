<template>
  <div class="row">
    <div class="col-12 col-xl-6">
      <div class="font-weight-bold">
        團購名稱：{{ detailInfo.groupbuying_title }}
      </div>
      <div class="font-weight-bold">
        團購編號：{{ detailInfo.groupbuying_number }}
      </div>
      <div>商品名稱：{{ detailInfo.title }}</div>
      <!-- <div>商品規格：{{ detailInfo.product.sku }}</div> -->
      <div>商品編號：{{ detailInfo.product.sale_page_code }}</div>
      <!-- <div>群組名稱：{{ detailInfo.name }}</div> -->
      <div>狀態：{{ detailInfo.status }}</div>
      <div>建立時間： {{ detailInfo.created_at }}</div>
      <div>開始時間： {{ detailInfo.start_at }}</div>
      <div>截止時間： {{ detailInfo.end_at }}</div>
    </div>

    <div class="col-12 col-xl-6">
      <div>
        本團銷售：{{ detailInfo.current_quantity || 0 }}
      </div>
      <div v-if="detailInfo.type === GROUPBUYING_TYPE.SCAN">
        本團分配庫存：{{ detailInfo.max_quantity || 0 }}
      </div>
      <!-- <div>商品剩餘總數：{{ detailInfo.quantity || 0 }}</div>
      <div>商品上架總數： {{ detailInfo.product.total_quantity || 0 }}</div>
      <div>
        本團成交金額：${{
          parseInt(detailInfo.groupbuying_product_order_total_price) || 0
        }}
      </div> -->
    </div>
  </div>
</template>

<script>
import { GROUPBUYING_TYPE } from "@/pages/Dashboard/Groupbuyings/utils";

export default {
  props: ["detailInfo"],
  data() {
    return {
      GROUPBUYING_TYPE,
    };
  },
  computed: {
    showAmount() {
      return Number(this.detailInfo.amount).toFixed(0);
    },
  },
};
</script>
