import https from "./https";
import store from "@/store";

const groupbuy = {

  getGroupbuyList(params) {
    const organization = store.state.general.organization;
    // const searchParams = new URLSearchParams(params); // 搜尋type要用[] 不然後端不過

    return https.get(`admin/organizations/${organization.id}/core-groupbuyings-v2`, {params});
  },

  getGroupbuyingOrders(groupbuying, params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams({
      ...params,
    });
    return https.get(
      `admin/organizations/${organization.id}/core-groupbuyings/${groupbuying}/groupbuying-orders?${searchParams}`
    );
  },

};

export default groupbuy;
