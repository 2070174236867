<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'CoreGroupbuyingsListV2',
              }"
              >團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>查看團購訂單</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-center">
        <div class="col-12 col-xl-2">
          <h4 class="font-weight-bold">查看團購</h4>
        </div>

        <div class="col-12 col-xl-8">
          <template v-if="status === GROUPBUYING_STATUS.WAITING">
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])"
              variant="primary"
              :to="{
                name: 'CoreGroupbuyingsViewV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >查看團購</b-button
            >
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_MODIFY])"
              class="ml-2"
              variant="warning"
              :to="{
                name: 'CoreGroupbuyingsEditV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >編輯團購</b-button
            >
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_MODIFY])"
              class="ml-2"
              variant="inverse-danger"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              @click="handleDelete"
              >刪除團購</b-button
            >
          </template>
          <template v-if="status === GROUPBUYING_STATUS.GOING">
            <b-button
              v-if="checkPermission([consts.GROUPBUYING_STATUS_MODIFY])"
              class="ml-2"
              variant="outline-primary"
              @click="handleClose"
              >結單</b-button
            >
            <b-button
              v-if="checkPermission([consts.GROUPBUYING_STATUS_MODIFY])"
              class="ml-2"
              variant="outline-primary"
              @click="handleCancel"
              >流團</b-button
            >
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])"
              variant="primary"
              :to="{
                name: 'CoreGroupbuyingsViewV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >查看團購</b-button
            >
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_MODIFY])"
              class="ml-2"
              variant="warning"
              :to="{
                name: 'CoreGroupbuyingsEditV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >編輯團購</b-button
            >
            <b-button
              v-if="checkPermission([consts.GROUPBUYING_STATUS_MODIFY])"
              class="ml-2"
              variant="outline-primary"
              @click="handlePending"
              >暫停收單</b-button
            >
          </template>
          <template v-if="status === GROUPBUYING_STATUS.CONFIRMED">
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])"
              variant="primary"
              :to="{
                name: 'CoreGroupbuyingsViewV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >查看團購</b-button
            >
            <b-button
              v-if="checkPermission([consts.GROUPBUYING_STATUS_MODIFY])"
              variant="outline-primary"
              @click="handleFinish"
              >完成團購</b-button
            >
            <!-- <b-button
              class="ml-2"
              variant="outline-primary"
              @click="isOpenGroupbuyingsLinkModal = true"
              >付款網址</b-button
            > -->
          </template>
          <template v-if="status === GROUPBUYING_STATUS.PENDING">
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])"
              variant="primary"
              :to="{
                name: 'CoreGroupbuyingsViewV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >查看團購</b-button
            >
            <b-button
              v-if="checkPermission([consts.GROUPBUYING_STATUS_MODIFY])"
              variant="outline-primary"
              @click="handleClose"
              >結單</b-button
            >
            <b-button
              v-if="checkPermission([consts.GROUPBUYING_STATUS_MODIFY])"
              class="ml-2"
              variant="outline-primary"
              @click="handleCancel"
              >流團</b-button
            >
            <b-button
              v-if="checkPermission([consts.GROUPBUYING_STATUS_MODIFY])"
              class="ml-2"
              variant="outline-primary"
              @click="handleResume"
              >恢復收單</b-button
            >
          </template>
          <template v-if="status === GROUPBUYING_STATUS.FINISHED">
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])"
              variant="primary"
              :to="{
                name: 'CoreGroupbuyingsViewV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >查看團購</b-button
            >
          </template>
          <template v-if="status === GROUPBUYING_STATUS.ARCHIVED">
            <b-button
              v-if="checkPermission([consts.CORE_GROUPBUYING_LIST_VIEW])"
              variant="primary"
              :to="{
                name: 'CoreGroupbuyingsViewV2',
                params: {
                  groupbuyID: groupbuyID,
                },
              }"
              :disabled="
                groupbuying.type === GROUPBUYING_TYPE.MAIN
              "
              >查看團購</b-button
            >
          </template>
        </div>
      </div>

      <b-overlay
        :show="isFetchGroupbuying"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block w-100"
      >
        <GroupbuyingsDetailInfo
          :detailInfo="detailInfo"
          :getGroupbuying="getGroupbuying"
        />
      </b-overlay>

      <div
        class="row d-flex mt-4 mb-2 justify-content-between align-items-center"
      >
        <h5 class="col-12 col-xl-6 font-weight-bold m-0">團購訂單</h5>
        <!-- <div class="col-12 col-xl-6 d-flex align-items-center">
          <b-button
            class="flex-shrink-0"
            variant="primary"
            @click="handleDownload"
            >下載</b-button
          >
          <b-input-group class="ml-4">
            <b-form-input placeholder="搜尋訂單ID、Line"></b-form-input>
            <b-input-group-append>
              <b-button><i class="fa fa-search"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </div> -->
      </div>

      <b-table
        striped
        hover
        responsive
        :items="groupbuying.orders"
        :fields="fields"
        :sort-by.sync="sortBy"
        :busy="isFetchGroupbuyingOrders"
      >
        <template #cell(quantity)="data">
          <a v-if="displayEdit(data.item)" href="" @click.prevent="handleQuantity(data.item)">
            {{ data.item.quantity }}
          </a>
          <span v-else>{{ data.item.quantity }}</span>
        </template>
        <template #cell(cancel)="data">
          <b-button v-if="displayCancel(data.item)" href="" @click.prevent="handleCancelOrder(data.item)" variant="inverse-danger">取消</b-button>
        </template>
        <template #cell(redeem_code)="data">
          {{ getRedeemCodeStatus(data.item.redeem_code) }}
        </template>
      </b-table>

      <GroupbuyingsQuantityModal
        v-if="editQuantityData"
        :show="isOpenQuantityModal"
        @update:show="isOpenQuantityModal = $event"
        @updated="getGroupbuying()"
        :data="editQuantityData"
        :groupbuying="groupbuying"
        :groupbuyingNumber="detailInfo.groupbuying_number"
      />

      <GroupbuyingsLinkModal
        v-if="isOpenGroupbuyingsLinkModal"
        :show="isOpenGroupbuyingsLinkModal"
        @update:show="isOpenGroupbuyingsLinkModal = $event"
        :detailInfo="detailInfo"
      />

      <div class="d-flex justify-content-center" style="margin-top: 50px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          @change="handleChangePage"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";
// import { BIconQuestionCircle } from "bootstrap-vue";
import GroupbuyingsDetailInfo from "./CoreGroupbuyingsDetailInfo.vue";
import groupbuyApi from "@/apis/groupbuy";
import coreGroupbuyApi from "@/apis/core-groupbuying";
import { getStatus, GROUPBUYING_STATUS, GROUPBUYING_TYPE } from "./utils";
import { statusMapping } from "./utils";
import GroupbuyingsQuantityModal from "./GroupbuyingsQuantityModal";
import GroupbuyingsLinkModal from "./GroupbuyingsLinkModal";
import checkPermission from "@/mixins/checkPermission";

export default {
  mixins: [checkPermission],
  components: {
    // BIconQuestionCircle,
    GroupbuyingsDetailInfo,
    GroupbuyingsQuantityModal,
    GroupbuyingsLinkModal,
  },
  data() {
    return {
      GROUPBUYING_STATUS,
      GROUPBUYING_TYPE,
      isFetchGroupbuying: false,
      isFetchGroupbuyingOrders: false,
      isActionLoading: false,
      isOpenQuantityModal: false,
      editQuantityData: null,
      groupbuying: {},
      sortBy: "created",
      perPage: 15,
      currentPage: 1,
      total: 0,

      isOpenGroupbuyingsLinkModal: false,
    };
  },

  watch: {
    currentBranch() {
      this.$router.push({
        name: "GroupbuyingsList",
      });
    },
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),

    status() {
      return getStatus(this.groupbuying.status);
    },



    detailInfo() {
      const {
        groupbuying_number,
        groupbuying_product_order_quantity,
        groupbuying_product_order_total_price,
        products = [],
        group,
        status,
        amount,
        current_quantity,
        max_quantity,
        created_at,
        start_at,
        end_at,
        type,
        title,
      } = this.groupbuying;
      return {
        type,
        groupbuying_title: title,
        groupbuying_number,
        groupbuying_product_order_quantity,
        groupbuying_product_order_total_price,
        title: products[0]?.title,
        name: group?.name,
        status: status && statusMapping[status.substring(0, 1)],
        quantity: products[0]?.sku_list[0]?.quantity,
        max_quantity,
        current_quantity: current_quantity ?? 0,
        amount: amount ?? 0,
        created_at:
          created_at && format(new Date(created_at), "yyyy-MM-dd HH:mm"),
        start_at: start_at && format(new Date(start_at), "yyyy-MM-dd HH:mm"),
        end_at: end_at && format(new Date(end_at), "yyyy-MM-dd HH:mm"),
        product: {
          sale_page_code: products[0]?.sale_page_code,
          price: products[0]?.price,
          sku: products[0]?.sku_list[0]?.sku_name ?? "",
          pic_url: products[0]?.image_list[0]?.pic_url ?? "",
          total_quantity:
            (products[0]?.sku_list[0]?.quantity ?? 0) +
            (products[0]?.sku_list[0]?.sale_quantity ?? 0),
        },
      };
    },

    fields() {
      // const showFieldsB = [
      //   GROUPBUYING_STATUS.CONFIRMED,
      //   GROUPBUYING_STATUS.FINISHED,
      //   GROUPBUYING_STATUS.PENDING,
      //   GROUPBUYING_STATUS.CANCELLED,
      // ].includes(this.status);

      const fieldsA = [
        {
          key: "order_number",
          label: "團購單編號",
        },
        {
          key: "customer_name",
          label: "LINE暱稱",
        },
        {
          key: "ec_member_no",
          label: "會員編號",
        },
        {
          key: "quantity",
          label: "下單組數",
          class: "text-center",
        },
        {
          key: "total_price",
          class: "text-right",
          label: "金額",
          formatter: (value) => {
            return value ? "$" + Number(value).toFixed(0) : "";
          },
        },
        // TODO: hide this
        // {
        //   key: "updated_at",
        //   label: "建立時間",
        //   class: "text-right",
        //   formatter: (value) => {
        //     return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
        //   },
        // },
      ];

      const fieldsB = [
        {
          key: "cancel",
          label: "操作",
        },
        ...fieldsA,
        // {
        //   key: "order.outer_order_number",
        //   label: "線上訂單編號",
        // },
        {
          key: "total_price",
          label: "實際金額",
        },
        {
          key: "created_at",
          label: "訂單成立時間",
          formatter: (value) => {
            return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
          },
        },
        // {
        //   key: "order.deadline_at",
        //   label: "取貨期限",
        //   formatter: (value) => {
        //     return value ? format(new Date(value), "yyyy-MM-dd HH:mm") : "";
        //   },
        // },
        {
          key: "status_label",
          label: "團購單狀態",
        },
        {
          key: "redeem_code",
          label: "其他",
        },
      ];

      return fieldsB;
    },

    groupbuyID() {
      return this.$route.params.groupbuyID;
    },
  },
  async created() {
    await this.getGroupbuying();
  },

  methods: {
    getRedeemCodeStatus(data) {
      if (data) {
        if (data.redeem_at) {
          return "已使用"
        } else {
          return "未使用"
        }
      }
      return "";
    },
    async getGroupbuying() {
      try {
        this.isFetchGroupbuying = true;
        const { data } = await groupbuyApi.getGroupbuying(this.groupbuyID);
        this.groupbuying = data.data;
        await this.getGroupbuyingOrders();
      } catch (error) {
        console.log("error", error);
      }
      this.isFetchGroupbuying = false;
    },

    async getGroupbuyingOrders() {
      try {
        this.isFetchGroupbuyingOrders = true;
        const { data } = await coreGroupbuyApi.getGroupbuyingOrders(
          this.groupbuyID,
          {
            page: this.currentPage,
            has_order: 0, // 只顯示有訂單的團購單
          }
        );
        this.groupbuying.orders = data.data;
        this.total = data.meta.total;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetchGroupbuyingOrders = false;
    },

    handleChangePage() {
      this.$nextTick(() => {
        this.getGroupbuyingOrders();
      });
    },
    handleCreate() {
      this.$router.push({ name: "GroupbuyingsCreate" });
    },

    handleDownload() {},

    async handleDelete() {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div> <strong>團購編號：${this.detailInfo.groupbuying_number}</strong></div>
                <div>商品名稱：${this.detailInfo.title}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await groupbuyApi.deleteGroupbuying(
                this.groupbuyID
              );
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                this.$router.push({
                  name: "GroupbuyingsList",
                });
              }
            } catch (error) {
              console.log("");
            }
          }
        });
    },

    handleClose() {
      this.$swal({
        title: "確定要結單嗎？",
        html: `
        <div class="d-block">
            <div>
              確認結單後，系統將發送付款連結到個別用戶的LINE，請提醒客人到 ${this.organization.name} LINE官方帳號確認訊息並完成付款喔！
            </div>
            <div class="my-3">
               <div>商品名稱：${this.detailInfo.title}</div>
                <div>群組名稱：${this.detailInfo.name}</div>
                <div>已下單組數：${this.detailInfo.current_quantity}</div>
            </div>
          </div>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "確定結單",
        cancelButtonText: "繼續團購",
        reverseButtons: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#fff",
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            await groupbuyApi.updateGroupbuyingStatus(
              this.groupbuyID,
              "confirm"
            );
            await this.$swal("已結單", "", "success");
            await this.getGroupbuying();
          } catch (error) {
            console.log("");
          }
        }
      });
    },

    handleCancel() {
      this.$swal({
        title: "確定要流團嗎？",
        html: `
        <div class="d-block">
          <div>若確認流團，系統將自動發送通知到此團的群組</div>
          <div class="my-3">
            <div>商品名稱：${this.detailInfo.title}</div>
            <div>群組名稱：${this.detailInfo.name}</div>
            <div>已下單組數：${this.detailInfo.current_quantity}</div>
          </div>
        </div>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "確定流團",
        cancelButtonText: "繼續團購",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            await groupbuyApi.updateGroupbuyingStatus(
              this.groupbuyID,
              "cancel"
            );
            await this.$swal("已流團", "", "success");
            await this.getGroupbuying();
          } catch (error) {
            console.log("");
          }
        }
      });
    },

    async handleResume() {
      try {
        await groupbuyApi.updateGroupbuyingStatus(this.groupbuyID, "resume");
        await this.$swal("已流團", "", "success");
        await this.getGroupbuying();
      } catch (error) {
        console.log("");
      }
    },

    async handleFinish() {
      this.$swal({
        title: "確定要完成團購嗎？",
        html: `
        <div class="d-block">
          <div>確認完成後，將無法修改數量，也無法下單結帳，<br/>請您確認！</div>
          <div class="my-3">
            <div>商品名稱：${this.detailInfo.title}</div>
            <div>群組名稱：${this.detailInfo.name}</div>
            <div>已下單組數：${this.detailInfo.current_quantity}</div>
          </div>
        </div>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "完成團購",
        cancelButtonText: "繼續團購",
        reverseButtons: true,
        confirmButtonClass: "btn btn-lg btn-primary m-1 h3",
        cancelButtonClass: "btn btn-lg btn-outline-primary m-1 h3",
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            await groupbuyApi.updateGroupbuyingStatus(
              this.groupbuyID,
              "finish"
            );
            await this.$swal("完成團購", "", "success");
            await this.getGroupbuying();
          } catch (error) {
            console.log("");
          }
        }
      });
    },

    async handlePending() {
      this.$swal({
        title: "確定要暫停收單嗎？",
        html: `
        <div class="d-block">
          <div>確認暫停後，客人將無法下單，也看不到此團購，<br/>請您確認！</div>
          <div class="my-3">
            <div>商品名稱：${this.detailInfo.title}</div>
            <div>群組名稱：${this.detailInfo.name}</div>
            <div>已下單組數：${this.detailInfo.current_quantity}</div>
          </div>
        </div>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "暫停收單",
        cancelButtonText: "繼續團購",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            await groupbuyApi.updateGroupbuyingStatus(this.groupbuyID, "pend");
            await this.$swal("暫停收單", "", "success");
            await this.getGroupbuying();
          } catch (error) {
            console.log("");
          }
        }
      });
    },

    handleQuantity(data) {
      this.isOpenQuantityModal = true;
      this.editQuantityData = data;
    },
    async handleCancelOrder(data) {
      const price = data.total_price ? "$" + Number(data.total_price).toFixed(0) : "";
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要取消嗎？",
          html: `
            <div class="d-block">
              <div class="my-3 text-left">
                <div>團購單編號：${data.order_number}</div>
                <div>線上訂單編號：${data.order.order_number}</div>
                <div>下單組數：${data.quantity}</div>
                <div>金額：${price}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定取消",
          cancelButtonText: "不取消",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await groupbuyApi.cancelGroupbuyingOrder(
                this.groupbuyID,
                data.id
              );
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "取消成功",
                  type: "success",
                });
                await this.getGroupbuying();
              }
            } catch (error) {
              console.error(error);
            }
          }
        });
      },
      displayCancel(item) {
        if (! this.$permissions.has(this.$permissions.consts.GROUPBUYING_ORDER_CANCEL)) {
          return false;
        }

        if (!item.status || !item.delivery_status) return false;
        if (item.status === 'Finish' && item.delivery_status === 'Finish') return false;
        if (item.status === 'Cancel') return false;
        return true;
      },
      displayEdit(item) {
        if (! this.$permissions.has(this.$permissions.consts.GROUPBUYING_ORDER_QTY_UPDATE)) {
          return false;
        }

        if (!item.status || !item.delivery_status) return false;
        if (item.status === 'Finish' && item.delivery_status === 'Finish') return false;
        if (item.status === 'Cancel') return false;
        return true;
      }
    },

};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
